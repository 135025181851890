<template>
  <contacts-add-edit v-model="contact" title="Add Client Contact" :entity-name="client.name" client-contact @onSave="save" @onLocationsChanged="updateLocations">
    <template #description>
      <p>An email will be sent to this contact to create an account used to login to the Client Portal. Contacts added to the client will have access to all locations.</p>
    </template>
  </contacts-add-edit>
</template>
<script>
import ContactsAddEdit from "@/modules/shared/contacts/ContactsAddEdit";
import { ADD_LOCATION_ASSIGNMENTS, INSERT_CLIENT_CONTACT_NEW_USER, REMOVE_LOCATION_ASSIGNMENTS } from "@/modules/shared/contacts/graph/mutations";
import { GET_CLIENT_DETAILS } from "@/modules/admin/clients/graph/queries";
import { authenticationService } from "@/services";

export default {
  components: { ContactsAddEdit },
  data: () => ({
    client: {
      name: "",
    },
    contact: {
      address: {
        country: "",
      },
      client: {
        location: [],
      },
    },
  }),
  methods: {
    updateLocations(locations) {
      this.contact.locations = locations.map((location) => ({ clientLocationID: location }));
    },
    save(contact) {
      this.$apollo
        .mutate({
          mutation: INSERT_CLIENT_CONTACT_NEW_USER,
          variables: {
            clientID: this.$route.params.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone.replace(/\D/g, ""),
            email: contact.email.toLowerCase(),
            password: "random",
            address1: contact.address.address1,
            address2: contact.address.address2,
            city: contact.address.city,
            postalCode: contact.address.postalCode,
            state: contact.address.state,
            country: contact.address.country,
            role: contact.role,
          },
        })
        .then(async ({ data }) => {
          const contactID = data.contact.id;
          this.updateContactLocationAssignment(contactID);
          if (contact.role == "CLIENT_OWNER") {
            await this.sendPasswordResetEmail(contact.email);
          }
        })
        .finally(() => this.$router.back());
    },
    updateContactLocationAssignment(contactID) {
      const add = [...this.contact.locations.map((location) => location.clientLocationID)];
      const remove = [];
      this.contact.client.locations.filter((location) => !add.includes(location.id)).forEach((id) => remove.push(id.id));
      if (remove.length > 0) {
        remove.forEach(
          async (clientLocationID) =>
            await this.$apollo.mutate({
              mutation: REMOVE_LOCATION_ASSIGNMENTS,
              variables: {
                contactID,
                clientLocationID,
              },
            })
        );
      }
      if (add.length > 0) {
        const arr = add.map((id) => ({ contactID, clientLocationID: id }));
        this.$apollo.mutate({
          mutation: ADD_LOCATION_ASSIGNMENTS,
          variables: {
            objects: arr,
          },
        });
      }
    },
    async sendPasswordResetEmail(email) {
      await authenticationService.requestPasswordReset(email);
    },
  },
  apollo: {
    client: {
      query: GET_CLIENT_DETAILS,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.clients[0],
      result({ data }) {
        const client = data.clients[0];
        this.contact.client = client;
      },
    },
  },
};
</script>
