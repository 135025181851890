import gql from "graphql-tag";

export const SAVE_USER = gql`
  mutation saveUser($user: users_insert_input!) {
    user: insert_users_one(object: $user) {
      id
    }
  }
`;

export const UPDATE_CLIENT_CONTACT = gql`
  mutation updatedClientContact(
    $contactID: uuid!
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $addressID: uuid!
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $state: String
    $country: String
    $userID: uuid!
    $role: roles_enum
  ) {
    update_contacts_by_pk(pk_columns: { id: $contactID }, _set: { firstName: $firstName, lastName: $lastName, phone: $phone, email: $email }) {
      addressID
      userID
    }
    update_addresses_by_pk(pk_columns: { id: $addressID }, _set: { address1: $address1, address2: $address2, city: $city, postalCode: $postalCode, state: $state, country: $country }) {
      id
    }
    update_users_by_pk(pk_columns: { id: $userID }, _set: { role: $role }) {
      id
    }
  }
`;

export const UPDATE_CLIENT_CONTACT_NO_USER = gql`
  mutation updatedClientContact(
    $contactID: uuid!
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $addressID: uuid!
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $state: String
    $country: String
  ) {
    update_contacts_by_pk(pk_columns: { id: $contactID }, _set: { firstName: $firstName, lastName: $lastName, phone: $phone, email: $email }) {
      addressID
      userID
    }
    update_addresses_by_pk(pk_columns: { id: $addressID }, _set: { address1: $address1, address2: $address2, city: $city, postalCode: $postalCode, state: $state, country: $country }) {
      id
    }
  }
`;

export const DELETE_CLIENT_CONTACT = gql`
  mutation DeleteClientContact($id: uuid!) {
    delete_contacts_by_pk(id: $id) {
      userID
    }
  }
`;

export const INSERT_CLIENT_CONTACT_NEW_USER = gql`
  mutation InsertClientContactNewUser(
    $clientID: uuid
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $state: String
    $country: String
    $role: roles_enum
  ) {
    contact: insert_contacts_one(
      object: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        clientID: $clientID
        user: { data: { username: $email, password: $password, role: $role } }
        address: { data: { address1: $address1, address2: $address2, city: $city, country: $country, postalCode: $postalCode, state: $state } }
      }
    ) {
      id
    }
  }
`;

export const INSERT_LOCATION_CONTACT_NEW_USER = gql`
  mutation InsertClientContactNewUser(
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $state: String
    $country: String
    $role: roles_enum
  ) {
    contact: insert_contacts_one(
      object: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        user: { data: { username: $email, password: $password, role: $role } }
        address: { data: { address1: $address1, address2: $address2, city: $city, country: $country, postalCode: $postalCode, state: $state } }
      }
    ) {
      id
    }
  }
`;

export const INSERT_CLIENT_CONTACT_EXISTING_USER = gql`
  mutation InsertClientContactExistingUser(
    $clientID: uuid
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $address1: String
    $address2: String
    $city: String
    $postalCode: String
    $state: String
    $country: String
    $userID: uuid
  ) {
    insert_contacts(
      objects: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        userID: $userID
        clientID: $clientID
        address: { data: { address1: $address1, address2: $address2, city: $city, country: $country, postalCode: $postalCode, state: $state } }
      }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_LOCATION_ASSIGNMENTS = gql`
  mutation RemoveALocationAssignment($contactID: uuid, $clientLocationID: uuid) {
    delete_clientLocationContacts(where: { contactID: { _eq: $contactID }, clientLocationID: { _eq: $clientLocationID } }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_LOCATION_ASSIGNMENTS = gql`
  mutation AddALocationAssignment($objects: [clientLocationContacts_insert_input!]!) {
    insert_clientLocationContacts(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_CONTACT_FROM_USER = gql`
  mutation DeleteClientUser($id: uuid!) {
    delete_users_by_pk(id: $id) {
      id
    }
  }
`;
