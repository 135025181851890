<template>
  <base-modal id="modal-delete-contact">
    <h4>Delete Contact</h4>
    <p>
      Are you sure you want to remove this contact from this
      <span v-if="!isClient">location?</span>
      <span v-else>client?</span>
    </p>
    <table class="no-zebra">
      <thead>
        <tr>
          <th>Contact Name</th>
          <th v-if="isClient">Client</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $props.contact.firstName }} {{ $props.contact.lastName }}</td>
          <td v-if="isClient">{{ $props.contact.client.name }}</td>
        </tr>
      </tbody>
    </table>
    <div class="button-group">
      <a class="button warning" @click="deleteContact">Confirm Delete</a>
      <a class="button secondary" @click="closeModal">Cancel</a>
    </div>
  </base-modal>
</template>

<script>
import micromodal from "micromodal";
import BaseModal from "@/components/modals/BaseModal";

export default {
  name: "ClientContactDeleteModal",
  components: { BaseModal },
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => {},
    },
    client: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    isClient() {
      return this.$props?.contact?.client?.name != null;
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },

  methods: {
    deleteContact() {
      this.$emit("delete-contact", this.$props.contact.id);
    },
    closeModal() {
      micromodal.close("modal-delete-contact");
    },
  },
};
</script>
