import gql from "graphql-tag";

export const GET_EMPLOYEES = gql`
  subscription getEmployees {
    employees(order_by: { firstName: asc, lastName: asc }) {
      id
      firstName
      lastName
      phone
      email
      role
      status
      user {
        id
        role
        username
      }
      accountManager {
        id
        name
      }
      teamsManaged {
        id
        team {
          id
          name
        }
      }
      specialist {
        id
        team {
          id
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  subscription getEmployee($id: uuid!) {
    employees_by_pk(id: $id) {
      id
      firstName
      lastName
      phone
      email
      role
      status
      accountManager {
        id
        name
      }
      teamsManaged {
        team {
          id
          name
        }
      }
      specialist {
        team {
          id
          name
        }
      }
      userID
    }
  }
`;

export const USER_EXISTS = gql`
  query findUser($username: String, $id: uuid!) {
    users(where: { _and: [{ username: { _eq: $username } }, { id: { _neq: $id } }] }) {
      id
    }
  }
`;

export const NEW_USER_EXISTS = gql`
  query findUser($username: String) {
    users(where: { username: { _eq: $username } }) {
      id
      username
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($employee: employees_insert_input!) {
    insert_employees_one(object: $employee) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation udpateEmployee($id: uuid!, $employee: employees_set_input!, $role: roles_enum, $userID: uuid!) {
    update_users_by_pk(_set: { role: $role }, pk_columns: { id: $userID }) {
      id
      updated_at
    }
    update_employees_by_pk(_set: $employee, pk_columns: { id: $id }) {
      id
    }
  }
`;
export const GET_EMPLOYEES_PAGNATION = gql`
  query getEmployeesPagination($role: [roles_enum!], $status: [String!], $limit: Int, $offset: Int, $fullName: String) {
    total: employees_aggregate(where: { role: { _in: $role }, _and: { status: { _in: $status } }, _or: { firstName: { _ilike: $fullName } } }) {
      aggregate {
        count
      }
    }
    employees(limit: $limit, offset: $offset, where: { role: { _in: $role }, _and: { status: { _in: $status } }, _or: { firstName: { _ilike: $fullName } } }) {
      id
      firstName
      lastName
      phone
      email
      role
      status
      user {
        id
        role
        username
      }
      accountManager {
        id
        name
      }
      teamsManaged {
        id
        team {
          id
          name
        }
      }
      specialist {
        id
        team {
          id
          name
        }
      }
    }
  }
`;
